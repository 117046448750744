<template>
  <v-overlay
    :value="onOff"
  >
    <v-progress-circular
      :size="70"
      :width="7"
      color="grey darken-2"
      indeterminate
    />
  </v-overlay>
</template>

<script>
  export default {
    props: {
      onOff: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
